export class AlertParam{
  title: string;
  text: string;
  btnLabel: string;
  btnIcon: string;
  type: 'success' | 'error' | null;
  constructor(_title: string, _text: string, _type: 'success' | 'error' | null = null, _btnIcon: string = '', _btnLabel: string = '') {
    this.title = _title;
    this.text = _text;
    this.type = _type;
    this.btnLabel = _btnLabel;
    this.btnIcon = _type == null ? _btnIcon : (_type == 'success' ? 'ok_icon.svg' : 'not_ok_icon.svg');
  }
}
